<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card class="el-main a-fs-14 projectadd">
			<div class="fnW600 font18 mgb36">角色信息</div>
			<el-form :model="form" ref="form" label-width="80px" :inline="false" size="mini"
				label-position="left">
                <el-form-item label="角色名称" prop="name">
					<span>{{ form.name || '-' }}</span>
				</el-form-item>
                <el-form-item label="角色描述" prop="description">
                    <span>{{ form.description || '-' }}</span>
				</el-form-item>
				<el-form-item label="所属商户" prop="companyId">
					<span>{{ form.companyName || '-' }}</span>
				</el-form-item>
				<el-form-item label="登录平台" prop="platform">
					<div class="a-flex-rfsc">
                        <div class="a-flex-rfsc" v-if="platform.indexOf('1') != -1">
                            <i class="el-icon-check a-c-blue"></i>
                            <span class="a-ml-08">WEB端</span>
                        </div>
                        <div class="a-flex-rfsc a-ml-24" v-if="platform.indexOf('2') != -1">
                            <i class="el-icon-check a-c-blue"></i>
                            <span class="a-ml-08">微信商户助手端</span>
                        </div>
                        <div class="a-flex-rfsc a-ml-24" v-if="platform.indexOf('3') != -1">
                            <i class="el-icon-check a-c-blue"></i>
                            <span class="a-ml-08">微信运维端</span>
                        </div>
                        <div class="a-flex-rfsc a-ml-24" v-if="platform.indexOf('4') != -1">
                            <i class="el-icon-check a-c-blue"></i>
                            <span class="a-ml-08">微信工程端</span>
                        </div>
                    </div>
				</el-form-item>
                <div class="pdb30" v-if="hasWeb">
                    <role-menu-info title="WEB端权限分配" type="web" :list="roleData.webList"></role-menu-info>
				</div>
				<div class="pdb30" v-if="hasWx">
                    <role-menu-info title="微信商户助手端权限分配" type="wx" :list="roleData.wxList"></role-menu-info>
				</div>
                <div class="pdb30" v-if="hasDevops">
                    <role-menu-info title="微信运维端权限分配" type="devops" :list="roleData.devopsList"></role-menu-info>
				</div>
                <div class="pdb30" v-if="hasEngineer">
                    <role-menu-info title="微信工程端权限分配" type="engineer" :list="roleData.engineerList"></role-menu-info>
				</div>
                <div style="height: 100px;"></div>
			</el-form>
            <div class="a-line-t-e0 flex_start footerBox">
                <el-button class="a-ml-24 a-mt-15" type="primary" @click="handlerCancel">关闭</el-button>
            </div>
		</el-card>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
    import RoleMenuInfo from './child/role-menu-info.vue';
	export default {
        components: {
            RoleMenuInfo
        },
		computed: {
            ...mapState({
                company: state => state.company.company,
            }),
			hasWeb() {
				let hasData = this.platform.find(val => val == 1) && true
				return hasData
			},
			hasWx() {
				let hasData = this.platform.find(val => val == 2) && true
				return hasData
			},
            hasDevops () {
                let hasData = this.platform.find(val => val == 3) && true
				return hasData
            },
            hasEngineer () {
                let hasData = this.platform.find(val => val == 4) && true
				return hasData
            },
		},
		data() {
			return {
				form: {
					id: null, //角色Id
					companyId: null,
					// dataPermission: 1,
					canLoginWxApp: 0,
					canLoginWeb: 0,
                    canLoginDevOpsApp: 0,
                    canLoginConstructionApp: 0,
					menuIdList: [],
                    name: '',// 角色名称
                    description: '',// 角色描述
				},
				platform: [],
                roleData: {
                    webList: [],
                    wxList: [],
                    devopsList: [],
                    engineerList: []
                }
			}
		},
		created() {
			if (this.$route.query.id) {
				this.form.id = parseInt(this.$route.query.id)
				this.form.companyId = parseInt(this.$route.query.companyId)
				this.getDetail()
			}else{
                this.form.companyId = this.company.id
            }
		},
		methods: {
			handlerCancel() {
				this.$store.dispatch("remove_current_view", this)
			},
			//获取详情
			getDetail() {
				this.$Axios._post({
					url: this.$Config.apiUrl.getRoleDetailById,
					params: {
						roleId: this.form.id
					}
				}).then(({
					data
				}) => {
					this.form = {
                        ...data.rbacRole,
                        companyName: data.companyName
                    }
                    this.roleData.webList = data.menuList.filter(item=>{
                        return item.appId == 'hichar.company.web.jd'
                    })
                    this.roleData.wxList = data.menuList.filter(item=>{
                        return item.appId == 'hichar.company.wxapp.jd'
                    })
                    this.roleData.devopsList = data.menuList.filter(item=>{
                        return item.appId == 'hichar.devops.wxapp.jd'
                    })
                    this.roleData.engineerList = data.menuList.filter(item=>{
                        return item.appId == 'hichar.engineer.wxapp.jd'
                    })
					if (this.roleData.webList.length) {
						this.platform.push("1")
					}
					if (this.roleData.wxList.length) {
						this.platform.push("2")
					}
                    if (this.roleData.devopsList.length) {
						this.platform.push("3")
					}
                    if (this.roleData.engineerList.length) {
						this.platform.push("4")
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.companyClass {
		padding: 0 !important;
        width: 400px;
		/deep/ .a-ml-20 {
			margin-left: 0;
		}
	}
</style>
