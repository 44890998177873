<template>
	<div class="border2">
		<div class="bgC8 pdtb10 pdl32">
			<span class="fontC2 font16">{{title}}</span>
		</div>
		<div class="pdl32 a-flex-rfsfs a-flex-wrap">
            <div class="a-ptb-18 w16p a-flex-cfsfs" style="align-items: stretch" v-for="(item, index) in list" :key="index">
                <div class="flex_start a-w-100">
                    <i class="el-icon-check fontC5"></i>
                    <span class="mgl10 a-flex-1 a-fw-700">{{item.name}}</span>
                </div>
                <div class="flex_start a-w-100 a-ml-24 a-pb-6" v-for="(ite,index) in item.children" :key="index">
                    <i class="el-icon-check fontC5"></i>
                    <span class="mgl10 a-flex-1 a-fs-12">{{ite.name}}</span>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String
			},
			type: {
				default: 'web'
			},
			list: {
				default: []
			},
		},
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
</style>
